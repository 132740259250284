import React, { forwardRef } from 'react';

const Loader = forwardRef((props, ref) => (

    <div ref={ref} className="background-loader">
        <div className="loader">
            <span className="spinner spinner1"></span>
            <span className="spinner spinner2"></span>
            <span className="spinner spinner3"></span>
        </div>
    </div>
));

export default Loader;
