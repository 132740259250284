import Navbar from '../components/Navbar';
import Slider from '../components/slider'
const Layout = () => {

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className="main-wrapper">
          <div className="content main_content">
            <Navbar />
              <Slider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
