import React, { useState, useRef, useEffect } from "react";
import Cropper from "react-cropper";
import uploadIcon from '../assets/images/upload-icon.svg'
import editIcon from '../assets/images/edit-icon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import "cropperjs/dist/cropper.css";

const Step3 = ({
  images,
  setImages,
  editingState,
  setEditingState,
  onNext,
  onPrev,
}) => {
  const [originalImage, setOriginalImage] = useState(images.originalImage);
  const [storyImage, setStoryImage] = useState(images.storyImage);
  const [postImage, setPostImage] = useState(images.postImage);
  const [editing, setEditing] = useState(editingState.editing);
  const [show, setShow] = useState(editingState.show);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [isMaxWindowSize, setIsMaxWindowSize] = useState(false);
  const [isLgWindowSize, setIsLgWindowSize] = useState(false);
  const [viewMode, setViewMode] = useState(10);

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slidesPerView: 1,
    mode: "free-snap",
    dragStart: false,
    drag: false,
    swipe: false,
    centered: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })
  window.scrollTo(0, 0);
  const storyCropperRef = useRef(null);
  const postCropperRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setEditingState({ editing, show });
  }, [editing, show, setEditingState]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result;
      setOriginalImage(result);
      setStoryImage(result);
      setPostImage(result);
      setImages({
        originalImage: result,
        storyImage: result,
        postImage: result,
      });
      setShow(true);
    };
    reader.readAsDataURL(file);
  };

  const handleEdit = (type) => {
    setEditing(type);
  };

  const handleNext = () => {
    setImages({
      originalImage,
      storyImage: storyImage || originalImage,
      postImage: postImage || originalImage,
    });
    onNext();
  };

  const handleCrop = () => {
    const cropperRef = editing === "story" ? storyCropperRef.current : postCropperRef.current;
    if (cropperRef) {
      const croppedImage = cropperRef.cropper.getCroppedCanvas().toDataURL();
      if (editing === "story") {
        setStoryImage(croppedImage);
      } else {
        setPostImage(croppedImage);
      }
      setEditing(null);
      setImages((prev) => ({
        ...prev,
        storyImage: editing === "story" ? croppedImage : prev.storyImage || originalImage,
        postImage: editing === "post" ? croppedImage : prev.postImage || originalImage,
      }));
    }
  };

  const undoChanges = () => {
    if (editing === "story") {
      setStoryImage(images.storyImage);
    } else {
      setPostImage(images.postImage);
    }
    setEditing(null);
  };


  useEffect(() => {
    const updateViewMode = () => {
      const currentWidth = window.innerWidth;
      if (currentWidth <= 676) {
        setViewMode(0);
      } else {
        setViewMode(10);
      }
    };

    updateViewMode();
    window.addEventListener('resize', updateViewMode);

    return () => window.removeEventListener('resize', updateViewMode);
  }, []);
  const renderImageArea = (type) => {
    const imageSrc = type === "story" ? storyImage : postImage;
    const cropperRef = type === "story" ? storyCropperRef : postCropperRef;
    const aspectRatio = type === "story" ? 9 / 16 : 1;

    return editing === type ? (
      <Cropper
        ref={cropperRef}
        src={originalImage}
        guides={true}
        zoomTo={0}
        viewMode={viewMode}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={true}
        responsive={true}
        // autoCropArea={0.93}
        autoCropArea={1}
        checkOrientation={true}
      />

    ) : (
      <div className={`image-preview ${type}-preview`}>
        {imageSrc && (
          <img
            src={imageSrc}
            alt={`${type} preview`}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  function Arrow(props) {
    const disabled = props.disabled ? " arrow--disabled" : ""
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${props.left ? "arrow--left" : "arrow--right"
          } ${disabled}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    )
  }

  useEffect(() => {
    const handleResize = () => {
      const isMaxWidth = window.innerWidth <= 1600;
      const isMaxHeight = window.innerHeight <= 900;
      const isLgWidth = window.innerWidth <= 1920;
      const isLgHeight = window.innerHeight >= 1080;

      setIsMaxWindowSize(isMaxWidth && isMaxHeight);
      setIsLgWindowSize(isLgWidth && isLgHeight)
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <section className="upload-image-section">
      <h2 className="section-heading">hlaða upp mynd</h2>
      <div className="image-editor">
        {!windowWidth <= 767 && <div class="editor-content desktop-mode">
          <div className="upload-area">
            <h5 className="upload-image-description story">
              samfélagsmiðlar – <strong> stories</strong><span>1080×1920</span>
            </h5>
            <div className="position-relative">
              {/* {show && !editing && (
                <div className="edit-ticket-icon">
                  <img
                    className="edit-ticket-icon-image"
                    src={editIcon}
                    alt="Edit Icon"
                    onClick={() => handleEdit("story")}
                  />
                </div>
              )} */}
              <div className={`story-area position-relative ${editing === "story" ? "overflowy-scroll overflow-hidden" : "overflow-visible"}`}>
                {renderImageArea("story")}
                {show && !editing && (
                  <div className="edit-ticket-icon">
                    <img
                      className="edit-ticket-icon-image"
                      src={editIcon}
                      alt="Edit Icon"
                      onClick={() => handleEdit("story")}
                    />
                  </div>
                )}
              </div>
              {editing === "story" && (
                <>
                  <button className="button-save" onClick={handleCrop}>
                    <FontAwesomeIcon className="me-2" icon={faSave} />
                    Vista
                  </button>
                  <button className="button-undo" onClick={undoChanges}>
                    <FontAwesomeIcon className="me-2" icon={faUndo} />
                    Afturkalla
                  </button>

                </>
              )}
            </div>
          </div>
          <div className="upload-area">
            <h5 className="upload-image-description post">
              samfélagsmiðlar – <strong>post</strong> <span>1080×1080</span>
            </h5>
            <div className="position-relative">
              {show && !editing && (
                <div className="edit-ticket-icon">
                  <img
                    className="edit-ticket-icon-image"
                    src={editIcon}
                    alt="Edit Icon"
                    onClick={() => handleEdit("post")}
                  />
                </div>

              )}
              <div className={`post-area ${editing === "post" ? "overflowy-scroll" : ""}`} style={{ position: 'relative' }}>

                {renderImageArea("post")}
              </div>
              {editing === "post" && (
                <>
                  <button className="button-save" onClick={handleCrop}>
                    <FontAwesomeIcon className="me-2" icon={faSave} />
                    Vista
                  </button>
                  <button className="button-undo" onClick={undoChanges}>
                    <FontAwesomeIcon className="me-2" icon={faUndo} />
                    Afturkalla
                  </button>
                </>
              )}
            </div>

          </div>

        </div>}
        <>
          {windowWidth <= 767 && <div className="navigation-wrapper responsive-mode">
            <div ref={sliderRef} className="keen-slider">
              <div className="keen-slider__slide number-slide1">
                <div className="editor-content">
                  <div className="upload-area">
                    <h5 className="upload-image-description story">
                      samfélagsmiðlar – <strong > stories</strong><span>1080×1920</span>
                    </h5>
                    <div className="position-relative">
                      {/* {show && !editing && (
                        <div className="edit-ticket-icon .story-edit-ticket-icon">
                          <img
                            className="edit-ticket-icon-image"
                            src={editIcon}
                            alt="Edit Icon"
                            onClick={() => handleEdit("story")}
                          />
                        </div>
                      )} */}

                      <div className="story-area position-relative">
                        {renderImageArea("story")}
                        {show && !editing && (
                          <div className="edit-ticket-icon .story-edit-ticket-icon">
                            <img
                              className="edit-ticket-icon-image"
                              src={editIcon}
                              alt="Edit Icon"
                              onClick={() => handleEdit("story")}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {editing === "story" && (
                      <div className="responsive-controls">

                        <button className="button-save" onClick={handleCrop}>
                          <FontAwesomeIcon className="me-2" icon={faSave} />
                          Vista
                        </button>
                        <button className="button-undo" onClick={undoChanges}>
                          <FontAwesomeIcon className="me-2" icon={faUndo} />
                          Afturkalla
                        </button>

                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="keen-slider__slide number-slide2">
                <div className="editor-content">
                  <div className="upload-area">
                    <h5 className="upload-image-description post">
                      samfélagsmiðlar – <strong>post</strong> <span>1080×1080</span>
                    </h5>
                    <div className="position-relative">
                      {show && !editing && (
                        <div className="edit-ticket-icon .post-edit-ticket-icon">
                          <img
                            className="edit-ticket-icon-image"
                            src={editIcon}
                            alt="Edit Icon"
                            onClick={() => handleEdit("post")}
                          />
                        </div>
                      )}
                      <div className="post-area" style={{ position: 'relative' }}>
                        {renderImageArea("post")}
                      </div>
                    </div>
                    {editing === "post" && (
                      <div className="responsive-controls">
                        <button className="button-save" onClick={handleCrop}>
                          <FontAwesomeIcon className="me-2" icon={faSave} />
                          Vista
                        </button>
                        <button className="button-undo" onClick={undoChanges}>
                          <FontAwesomeIcon className="me-2" icon={faUndo} />
                          Afturkalla
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {loaded && instanceRef.current && (
                <>
                  <Arrow
                    left
                    onClick={(e) =>
                      e.stopPropagation() || instanceRef.current?.prev()
                    }
                    disabled={currentSlide === 0}
                  />

                  <Arrow
                    onClick={(e) =>
                      e.stopPropagation() || instanceRef.current?.next()
                    }
                    disabled={
                      currentSlide ===
                      instanceRef.current.track.details.slides.length - 1
                    }
                  />
                </>
              )}
            </div>

          </div>}
        </>
        <button
          className={`upload-button  ${isMaxWindowSize && editing === "story" ? 'max-window-size' : ''} ${isLgWindowSize && editing === "story" ? 'lg-window-size' : ''}`}
          onClick={() => fileInputRef.current.click()}
        >
          {originalImage ? (
            <span className="d-flex align-items-center justify-content-between">
              Skipta um mynd
              <img className="ms-3 img-fluid" src={editIcon} alt="Edit Icon" />
            </span>
          ) : (
            <div className="d-flex align-items-center justify-content-between">
              Hlaða upp mynd
              <img className="ms-3 img-fluid" src={uploadIcon} alt="Upload Icon" />
            </div>

          )}
        </button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
      </div>
    </section>

  );
};

export default Step3;


