import React, { useState, useEffect, useRef } from "react";

const Step1 = ({ ticketData, handleChange, inputValue }) => {
  const [exceededMaxLength, setExceededMaxLength] = useState(false);
  const [displayedValue, setDisplayedValue] = useState(inputValue);
  const [maxHeight, setMaxHeight] = useState("3em"); // Default max-height for desktop
  const ticketNameRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFocus = (event) => {
    event.target.removeAttribute("placeholder");
  };

  const handleBlur = (event) => {
    event.target.setAttribute("placeholder", "Skrifaðu þinn texta …");
    window.scrollTo(0, 0);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length <= 18) {
      handleChange(event);
      setExceededMaxLength(false); // Reset the state when the input length is within limit
    } else {
      setExceededMaxLength(true); // Set the state if the input length exceeds the limit
    }
  };

  const adjustText = () => {
    const ticketNameElement = ticketNameRef.current;
    ticketNameElement.textContent = inputValue;

    let originalFontSize = parseInt(window.getComputedStyle(ticketNameElement).fontSize, 10);
    const originalHeight = ticketNameElement.clientHeight;
    const originalWidth = ticketNameElement.clientWidth;

    ticketNameElement.style.whiteSpace = "pre-wrap";
    ticketNameElement.style.lineBreak = "auto";

    // Function to check if the text exceeds the original dimensions
    const isTextOverflowing = () =>
      ticketNameElement.scrollHeight > originalHeight || ticketNameElement.scrollWidth > originalWidth;

    // Adjust font size if text is overflowing
    while (isTextOverflowing() && originalFontSize > 0) {
      originalFontSize -= 1;
      ticketNameElement.style.fontSize = `${originalFontSize}px`;
    }

    // Ensure text is always visible
    ticketNameElement.style.visibility = "visible";

    setDisplayedValue(ticketNameElement.textContent);
  };

  useEffect(() => {
    adjustText();
    // Event listener for window resize
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setMaxHeight("3em"); // Mobile max-height
      } else {
        setMaxHeight("3em"); // Desktop max-height
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [inputValue]);

  // Update displayedValue when inputValue changes
  useEffect(() => {
    adjustText();
    setDisplayedValue(inputValue);
  }, [maxHeight]);

  return (
    <section className="ticket-section">
      <div className="step">
        <h2 className="section-heading">búðu til þinn eigin miða</h2>
        <div style={{ position: "relative", display: "inline-block" }}>
          <img src={ticketData.imageUrl} alt="Ticket" />
          <div
            ref={ticketNameRef}
            className="ticket-name"
            style={{
              whiteSpace: "pre-wrap",
              overflow: "hidden",
              lineBreak: "auto",
              maxHeight: maxHeight, // Dynamic max-height based on window width
            }}
          >
            {displayedValue}
          </div>
        </div>
        <input
          id="myInput"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          maxLength={18}
          placeholder="Skrifaðu þinn texta …"
          autoComplete="off"
        />
        {exceededMaxLength && (
          <div className="text-danger mt-1">
            Nafn miða má ekki vera meira en 18 stafir.
          </div>
        )}
      </div>
      <style jsx>{`
        .ticket-name {
          white-space: pre-wrap;
          overflow: hidden;
          line-break: auto;
        }
      `}</style>
    </section>
  );
};

export default Step1;
