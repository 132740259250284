import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './layouts/Layout';
import ThankYou from '../src/components/ThankYou'
import './App.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Layout />} /> 
        <Route path="/thankyou" element={<ThankYou/>} /> 
      </Routes>
    </Router>
  );
}

export default App;
