import React, { useState } from "react";
import * as Yup from "yup";

const validationSchema = Yup.object({
  fullName: Yup.string().required("Vinsamlegast sláðu inn fullt nafn"),
  email: Yup.string().email("ógilt netfang").required("Vinsamlegast sláðu inn netfang"),
  phoneNumber: Yup.string().matches(/^\+?[0-9]{7,14}$/, "Símanúmer er ekki gilt").required("Vinsamlegast sláðu inn símanúmer"),
  advertiseAccess: Yup.boolean(),
});

const Step2 = ({ formData, onNext, onUpdateFormData }) => {
  const [values, setValues] = useState({
    fullName: formData.fullName || "",
    email: formData.email || "",
    phoneNumber: formData.phoneNumber || "",
    advertiseAccess: formData.advertiseAccess || false,
  });
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
  });

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: newValue,
    }));
    onUpdateFormData(name, newValue);
  };

  const handleBlur = async (e) => {
    const { name, value } = e.target;
    try {
      await validationSchema.validateAt(name, { [name]: value });
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }

    // Set placeholder on blur
    if (name === "fullName") {
      e.target.setAttribute("placeholder", "Fullt nafn");
    } else if (name === "email") {
      e.target.setAttribute("placeholder", "Netfang");
    } else if (name === "phoneNumber") {
      e.target.setAttribute("placeholder", "Símanúmer");
    }
    window.scrollTo(0, 0);
  };

  const handleFocus = (e) => {
    // Remove placeholder on focus
    e.target.removeAttribute("placeholder");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(values, { abortEarly: false });
      // Upload data to Dropbox and call onNext
      onNext(values);
    } catch (error) {
      error.inner.forEach((e) => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [e.path]: e.message,
        }));
      });
    }
  };

  return (
    <section className="form-section">
      <h2 className="section-heading">fylltu inn þínar upplýsingar</h2>
      <form onSubmit={handleSubmit} className="step">
        <div className="ticket-form-input">
          <input
            type="text"
            name="fullName"
            value={values.fullName}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            placeholder="Fullt nafn"
          />
          {errors.fullName && <div className="text-center mt-2 ticket-error">{errors.fullName}</div>}
        </div>

        <div className="ticket-form-input">
          <input
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            placeholder="Netfang"
          />
          {errors.email && <div className="text-center mt-2 ticket-error">{errors.email}</div>}
        </div>
        <div className="ticket-form-input">
          <input
            type="tel"
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            placeholder="Símanúmer"
          />
          {errors.phoneNumber && <div className="text-center mt-2 ticket-error">{errors.phoneNumber}</div>}
        </div>

        <div className="form-check form-switch form-switch-md">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckChecked"
            name="advertiseAccess"
            checked={values.advertiseAccess}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
            Ég gef leyfi fyrir notkun í auglýsingaefni.
          </label>
        </div>

      </form>
    </section>
  );
};

export default Step2;
