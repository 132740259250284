import React, { useRef, useEffect, useState } from "react";
import Moveable from "react-moveable";
import MoveableHelper from "moveable-helper";
import html2canvas from "html2canvas";
import { Modal } from 'react-bootstrap';
import { useKeenSlider } from "keen-slider/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebookF, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import Loader from "./Loader";
import "keen-slider/keen-slider.min.css"

const Step4 = ({ ticketData, images, accessToken, email, handleUploadBothImages }) => {
  window.scrollTo(0, 0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false)
  const loaderRef = useRef(null);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slidesPerView: 1,
    mode: "free-snap",
    dragStart: false,
    drag: false,
    swipe: false,
    centered: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })
  const [facebookShareUrl, setFacebookShareUrl] = useState("");
  const [twitterShareUrl, setTwitterShareUrl] = useState("");
  const [instagramShareUrl, setInstagramShareUrl] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth <= 767) {
      instanceRef.current?.update();
    }
  }, [windowWidth]);

  const handleResize = (e = {}) => {
    const { target, width, height } = e;
    if (!target || !width || !height) {
      return;
    }

    // Define the base dimensions and corresponding font size and line height
    const baseWidth = 200;
    const baseFontSize = 18;
    const baseLineHeight = 19;

    // Calculate the resize ratio based on the width
    const resizeRatio = width / baseWidth;

    // Calculate the new font size and line height based on the resize ratio
    const newFontSize = baseFontSize * resizeRatio;
    const newLineHeight = baseLineHeight * resizeRatio;

    // Apply the new font size and line height to the ticket name
    const ticketName = target.querySelector('.ticket-name');
    if (ticketName) {
      ticketName.style.fontSize = `${newFontSize}px`;
      ticketName.style.lineHeight = `${newLineHeight}px`;
    }
  };
  handleResize();

  function Arrow(props) {
    const disabled = props.disabled ? " arrow--disabled" : ""
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${props.left ? "arrow--left" : "arrow--right"
          } ${disabled}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    )
  }
  const overlayRefs = useRef({ storyImage: null, postImage: null });
  const [showControls, setShowControls] = useState(false);
  const helperRef = useRef(new MoveableHelper());
  const [finalImages, setFinalImages] = useState({
    storyImage: images.storyImage || images.originalImage,
    postImage: images.postImage || images.originalImage,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [imageLink, setImageLink] = useState('');
  const [apiCallMade, setApiCallMade] = useState({
    storyImage: false,
    postImage: false,
  });

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setFinalImages({
      storyImage: images.storyImage || images.originalImage,
      postImage: images.postImage || images.originalImage,
    });
  }, [images]);
  // Define the base width of the ticket image
  const baseWidth = 195;
  // Define the base font size for the ticket name
  const baseFontSize = 18;
  // Define the base line height for the ticket name
  const baseLineHeight = 23;
  // Define the base padding for the left and right sides
  const basePadding = 10;

  const captureAndProcessImage = (index, type, share = true) => {
    return new Promise((resolve, reject) => {
      const sectionClassName = `image-section-${index + 1}`;
      const section = document.querySelector(`.${sectionClassName}`);

      if (!section) {
        console.error(`Element with class ${sectionClassName} not found.`);
        return;
      }
      const desiredDimensions = {
        storyImage: { width: 1080, height: 1920 },
        postImage: { width: 1080, height: 1080 }
      };

      const { width: desiredWidth, height: desiredHeight } = desiredDimensions[type] || desiredDimensions.postImage;

      const fontStyles = {
        storyImage: { fontWeight: '900 !important' },
        postImage: { fontWeight: '900 !important' }
      };

      const maxHeightStyles = {
        storyImage: {
          desktop: { minHeight: 'calc(1.58em * 2.4)', marginTop: '0px' },
          mobile: { minHeight: 'calc(1.62em * 2.3)', lineClamp: 2 }
        },
        postImage: {
          desktop: { minHeight: 'calc(1.58em * 2.4)', marginTop: '10px' },
          mobile: { minHeight: 'calc(1.62em * 2.3)', marginTop: '1px', lineClamp: 2 }
        }
      };

      const extractBackgroundImageUrl = (element) => {
        const imageElement = element.querySelector('.image-preview, .trending-image');
        return imageElement ? imageElement.src : null;
      };

      const loadImage = (src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = "Anonymous"; // Ensure CORS handling
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = src;
        });
      };

      const backgroundImageUrl = extractBackgroundImageUrl(section);

      if (!backgroundImageUrl) {
        console.error("No background image found.");
        return;
      }

      loadImage(backgroundImageUrl).then((backgroundImage) => {
        const offscreenCanvas = document.createElement('canvas');
        offscreenCanvas.width = desiredWidth;
        offscreenCanvas.height = desiredHeight;
        const offscreenCtx = offscreenCanvas.getContext('2d');

        // Calculate aspect ratios
        const imgAspectRatio = backgroundImage.width / backgroundImage.height;
        const canvasAspectRatio = desiredWidth / desiredHeight;

        // Calculate dimensions and positioning for cover effect
        let drawWidth, drawHeight, drawX, drawY;
        if (imgAspectRatio > canvasAspectRatio) {
          drawWidth = desiredHeight * imgAspectRatio;
          drawHeight = desiredHeight;
          drawX = (desiredWidth - drawWidth) / 2;
          drawY = 0;
        } else {
          drawWidth = desiredWidth;
          drawHeight = desiredWidth / imgAspectRatio;
          drawX = 0;
          drawY = (desiredHeight - drawHeight) / 2;
        }

        // Draw the scaled and positioned background image to cover the canvas
        offscreenCtx.drawImage(backgroundImage, drawX, drawY, drawWidth, drawHeight);

        html2canvas(section, {
          allowTaint: true,
          removeContainer: true,
          backgroundColor: 'transparent',
          scale: 6,
          useCORS: true,
          onclone: (clonedDocument) => {
            const clonedSection = clonedDocument.querySelector(`.${sectionClassName}`);
            if (!clonedSection) {
              console.error(`Cloned element with class ${sectionClassName} not found.`);
              return;
            }

            const clonedOverlay = clonedSection.querySelector(`.${type}-overlay`);
            const originalOverlay = overlayRefs.current[type];
            const computedStyle = window.getComputedStyle(originalOverlay);
            const rect = clonedOverlay.getBoundingClientRect();

            if (clonedOverlay) {
              clonedOverlay.style.width = `${Math.ceil(rect.width)}px`;
              clonedOverlay.style.height = `${Math.ceil(rect.height)}px`;

              Object.assign(clonedOverlay.style, {
                width: computedStyle.width,
                height: computedStyle.height,
                transform: computedStyle.transform,
                transformOrigin: computedStyle.transformOrigin,
                overflow: 'visible',
              });
            }

            clonedSection.style.background = 'transparent';
            const imageElements = clonedSection.querySelectorAll('.image-preview, .trending-image');
            imageElements.forEach(img => {
              img.style.display = 'none'; // Hide the original images
            });

            clonedSection.style.backgroundColor = 'transparent';
            clonedSection.style.border = 'none';
            clonedSection.style.borderRadius = '0px';
            Array.from(clonedSection.querySelectorAll("*")).forEach(el => {
              el.style.backgroundColor = 'transparent';
            });

            const ticketContainer = clonedSection.querySelector('.ticket-overlay');

            if (ticketContainer) {
              ticketContainer.style.objectFit = 'cover';
            }

            const ticketName = clonedSection.querySelector('.ticket-name');
            // Calculate the width of the ticket image
            const ticketImageWidth = ticketContainer.offsetWidth;
            // Calculate the resize ratio based on the width of the ticket image
            const resizeRatio = ticketImageWidth / baseWidth;
            // Calculate the new font size and line height based on the resize ratio
            const newFontSize = baseFontSize * resizeRatio;
            const newLineHeight = baseLineHeight * resizeRatio;
            // Calculate the new padding based on the resize ratio
            const newPadding = basePadding * resizeRatio;
            // Apply the new font size, line height, and padding to the ticket name
            if (ticketName) {
              ticketName.style.fontSize = `${newFontSize}px`;
              ticketName.style.lineHeight = `${newLineHeight}px`;
              ticketName.style.paddingLeft = `${newPadding}px`;
              ticketName.style.paddingRight = `${newPadding}px`;
              ticketName.style.display = 'flex !important';
              ticketName.style.alignItems = 'center !important'; // Center vertically
              ticketName.style.justifyContent = 'center '; // Center horizontally
              ticketName.style.marginTop = '0px'; // Set margin top to 0
              ticketName.style.fontWeight = '400';

              Object.assign(ticketName.style, {
                boxShadow: 'none',
                textShadow: 'none',
                top: '55%',
                WebkitLineClamp: '2',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                zIndex: '2',
                ...fontStyles[type],
              });
              // Adjust max-height based on screen size and type
              const screenSize = window.matchMedia("(max-width: 767px)").matches ? 'mobile' : 'desktop';
              ticketName.style.minHeight = maxHeightStyles[type][screenSize].minHeight;
              const words = ticketName.textContent.split(/\s+/);
              if (words.length > 8) {
                ticketName.style.marginTop = maxHeightStyles[type][screenSize].marginTop;
              } else {
                ticketName.style.marginTop = '0';
              }
            }
          }
        }).then(originalCanvas => {
          const scaleX = desiredWidth / originalCanvas.width;
          const scaleY = desiredHeight / originalCanvas.height;
          const scale = Math.min(scaleX, scaleY);
          const x = (desiredWidth / 2) - (originalCanvas.width / 2) * scale;
          const y = (desiredHeight / 2) - (originalCanvas.height / 2) * scale;

          offscreenCtx.drawImage(originalCanvas, 0, 0, originalCanvas.width, originalCanvas.height, x, y, originalCanvas.width * scale, originalCanvas.height * scale);

          //       const dataUrl = offscreenCanvas.toDataURL("image/png");
          //       if (share) {
          //         uploadToShare(dataUrl, `${ticketData.ticketName}${"("}${email}${")"}${index + 1}.png`);
          //       } else {
          //         uploadToDropbox(dataUrl, `${ticketData.ticketName}${"("}${email}${")"}${index + 1}.png`);
          //       }
          //       handleImageProcessing(dataUrl, section, share, index, type);
          //     }).catch(error => {
          //       console.error("Error capturing the screenshot:", error);
          //     });
          //   }).catch(error => {
          //     console.error("Error loading background image:", error);
          //   });
          // };
          const dataUrl = offscreenCanvas.toDataURL("image/png");
          if (share) {
            // uploadToShare(dataUrl, `${ticketData.ticketName}${"("}${email}${")"}${index + 1}.png`);
          } else {
            uploadToDropbox(dataUrl, `${ticketData.ticketName}${"("}${email}${")"}${index + 1}.png`);
          }
          handleImageProcessing(dataUrl, section, share, index, type);
          resolve(dataUrl);
        }).catch(error => {
          console.error("Error capturing the screenshot:", error);
          reject(error);
        });
      }).catch(error => {
        console.error("Error loading background image:", error);
        reject(error);
      });
    });
  };
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const handleUploadBothImagesLocal = async () => {
    if (isIOS) {
      try {
         loaderRef.current.style.display = 'block';
        const modal = document.getElementById('savePhotoModal')
         modal.style.opacity = '0';
        const storyImageDataUrl = await captureAndProcessImage(0, 'storyImage');
        const postImageDataUrl = await captureAndProcessImage(1, 'postImage');
        await uploadToDropbox(storyImageDataUrl, `${ticketData.ticketName}${"("}${email}${")"}${"(Story)"}.png`);
        await uploadToDropbox(postImageDataUrl, `${ticketData.ticketName}${"("}${email}${")"}${"(Post)"}.png`);
        setModalOpen(false);
      } catch (error) {
        console.error('Error downloading or uploading images:', error);
      }
      finally{
        if (loaderRef.current) {
          loaderRef.current.style.display = 'none';
        }
      }
      return;
    }
    try {
      loaderRef.current.style.display = 'block';
    
      const storyImageDataUrl = await captureAndProcessImage(0, 'storyImage');
      const postImageDataUrl = await captureAndProcessImage(1, 'postImage');
    
      // Create download links
      const storyLink = document.createElement('a');
      storyLink.href = storyImageDataUrl;
      storyLink.download = `${ticketData.ticketName}(Story).png`;
    
      const postLink = document.createElement('a');
      postLink.href = postImageDataUrl;
      postLink.download = `${ticketData.ticketName}(Post).png`;
    
      // Append links to the body to make sure they work on Safari
      document.body.appendChild(storyLink);
      document.body.appendChild(postLink);
    
      // Function to trigger download with delay
      const triggerDownload = (link, delay) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            // Force a repaint
            void document.body.offsetHeight;
            link.click();
            link.remove();
            resolve();
          }, delay);
        });
      };
    
      // Trigger downloads sequentially with delay
      await triggerDownload(storyLink, 500); 
      await triggerDownload(postLink, 500);
    
      // Upload to Dropbox
      await uploadToDropbox(storyImageDataUrl, `${ticketData.ticketName}(${email})(Story).png`);
      await uploadToDropbox(postImageDataUrl, `${ticketData.ticketName}(${email})(Post).png`);
    
      setModalOpen(false);
    } catch (error) {
      console.error('Error downloading or uploading images:', error);
    }
    
    finally{
      if (loaderRef.current) {
            loaderRef.current.style.display = 'none';
          }
    }
  };

  useEffect(() => {
    handleUploadBothImages(() => handleUploadBothImagesLocal);
  }, []);

  async function handleImageProcessing(dataUrl, section, share, index, type) {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const setModalOpen = (isOpen) => {
      const modal = document.getElementById("savePhotoModal");
      modal.style.display = isOpen ? "block" : "none";
    };
    const displayImage = (dataUrl) => {
      const displayedImage = document.getElementById("displayedImage");
      if (isIOS) {
        displayedImage.src = dataUrl;
        setModalOpen(true);
      }
    };
    // Detect if the device is mobile (iOS or Android)
    const isMobile = /iPad|iPhone|iPod|Android/.test(navigator.userAgent) && !window.MSStream;
    if (share) {
      if (isMobile) {
        // Show the image in a modal for mobile devices
        displayImage(dataUrl);
        const objectUrl = await convertDataUrlToBlob(dataUrl);
        if (objectUrl) {
          try {
            setImageLink(objectUrl);
            // await navigator.clipboard.writeText(objectUrl);
          } catch (err) {
            console.error(err);
          }
        }
        // Handle the image tap to close the modal
        const displayedImage = document.getElementById("displayedImage");
        displayedImage.onclick = function () {
          setModalOpen(false);
        };
        const closeIcon = document.getElementById("closeIcon");
        // Add event listener to close icon
        closeIcon.addEventListener("click", function () {
          // Hide the displayed image
          const displayedImage = document.getElementById("displayedImage");
          displayedImage.src = null;
          // Close the modal
          setModalOpen(false);
        });
      } else {
        // Desktop behavior: same as before (assuming no specific behavior provided for share on desktop)
        const objectUrl = await convertDataUrlToBlob(dataUrl);
        if (objectUrl) {
          try {
            setImageLink(objectUrl);
            // await navigator.clipboard.writeText(objectUrl);
          } catch (err) {
            console.error(err);
          }
        }
      }
    } else {
      // Create the download link element
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${ticketData.ticketName}${index + 1}.png`;
      // Detect if the device is iOS
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      // Prevent rendering if on Safari on iPhone
      if (isIOS) {
        // Display the image in a modal for iOS
        displayImage(dataUrl);
        // Handle the image tap to save and close
        const displayedImage = document.getElementById("displayedImage");
        displayedImage.onclick = function () {
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // Close the modal after initiating the download
          setModalOpen(false);
          // Hide the displayed image
          // displayedImage.style.display = "none";
          displayedImage.src = null;
        };

      } else {
        // For non-mobile devices, use the download link directly
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      // Get the close icon element
      const closeIcon = document.getElementById("closeIcon");

      // Add event listener to close icon
      closeIcon.addEventListener("click", function () {
        // Hide the displayed image
        const displayedImage = document.getElementById("displayedImage");
        displayedImage.src = null;

        // Close the modal
        setModalOpen(false);
      });
    }
  }
  async function convertDataUrlToBlob(dataUrl) {
    try {
      const response = await fetch(dataUrl);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error converting data URL to Blob:', error);
      return null;
    }
  }
  const handleMouseEnter = () => setShowControls(true);
  const handleMouseLeave = () => setShowControls(false);

  const handleCapture = (index, type) => {
    captureAndProcessImage(index, type, false);
    setModalOpen(false);
  };
  const handleShare = async (index, type) => {
    try {
      const dataUrl = await captureAndProcessImage(index, type, true);
      if (dataUrl) {
        await uploadToShare(dataUrl, `${ticketData.ticketName}${index + 1}.png`);
      }
    } catch (error) {
      console.error('Error sharing image:', error);
    }
  };
  const uploadToDropbox = async (imageData, filename) => {
    try {
      // Convert the data URL to a Blob object
      const response = await fetch(imageData);
      if (!response.ok) {
        throw new Error(`Failed to fetch image data: ${response.statusText}`);
      }

      const blob = await response.blob();
      // Prepare request headers
      const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/octet-stream',
        'Dropbox-API-Arg': JSON.stringify({
          path: `/images/${filename}`,
          mode: 'add',
          autorename: true,
          mute: false,
        }),
      };

      // Send the Blob as binary payload with appropriate headers
      const uploadResponse = await fetch('https://content.dropboxapi.com/2/files/upload', {
        method: 'POST',
        headers: headers,
        body: blob
      });

      if (uploadResponse.ok) {
        const result = await uploadResponse.json();
        return result;  // Return the result for potential further processing
      } else {
        const errorText = await uploadResponse.text();
        console.error('Failed to upload image to Dropbox. Response:', errorText);
        throw new Error(`Failed to upload image to Dropbox. Status: ${uploadResponse.status} Response: ${errorText}`);
      }
    } catch (error) {
      console.error('Error uploading image to Dropbox:', error);
      throw error;
    }
  };
  const createHtmlTemplate = (imageUrl, title, description, url) => {
    return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta property="og:title" content="${title}">
        <meta property="og:description" content="${description}">
        <meta property="og:image" content="${imageUrl}">
        <meta property="og:url" content="${url}">
        <title>${title}</title>
    </head>
    <body>
        <h1>${title}</h1>
        <img src="${imageUrl}" alt="${description}">
    </body>
    </html>
    `;
  };
  const uploadToShare = async (imageData, filename, title = 'FYRSTA HLAUPIÐ', description = 'REYKJAVÍKUR MARAÞON ÍSLANDSBANKA 40 ÁRA') => {
    try {
      // Set loading state to true
      if (loaderRef.current && windowWidth > 767) {
        loaderRef.current.style.display = 'block';
      }

      // Convert the data URL to a Blob object
      const response = await fetch(imageData);
      if (!response.ok) {
        throw new Error(`Failed to fetch image data: ${response.statusText}`);
      }

      const blob = await response.blob();

      // Prepare request headers
      const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/octet-stream',
        'Dropbox-API-Arg': JSON.stringify({
          path: `/images/${filename}`,
          mode: 'add',
          autorename: true,
          mute: false,
        }),
      };

      // Send the Blob as binary payload with appropriate headers
      const uploadResponse = await fetch('https://content.dropboxapi.com/2/files/upload', {
        method: 'POST',
        headers: headers,
        body: blob
      });

      if (!uploadResponse.ok) {
        throw new Error(`Failed to upload image to Dropbox. Status: ${uploadResponse.status} Response: ${uploadResponse.statusText}`);
      }

      const result = await uploadResponse.json();

      // Check if a shared link already exists for the file
      const listSharedLinksResponse = await fetch('https://api.dropboxapi.com/2/sharing/list_shared_links', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          path: result.path_lower,
          direct_only: true
        })
      });

      if (!listSharedLinksResponse.ok) {
        throw new Error(`Failed to list shared links: ${listSharedLinksResponse.statusText}`);
      }

      const listSharedLinksResult = await listSharedLinksResponse.json();
      let sharedLinkUrl = null;

      if (listSharedLinksResult.links.length > 0) {
        sharedLinkUrl = listSharedLinksResult.links[0].url;
      } else {
        // Create a new shared link
        const createSharedLinkResponse = await fetch('https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            path: result.path_lower,
            settings: {
              requested_visibility: 'public'
            }
          })
        });

        if (!createSharedLinkResponse.ok) {
          const errorText = await createSharedLinkResponse.text();
          throw new Error(`Failed to create shared link. Status: ${createSharedLinkResponse.status} Response: ${errorText}`);
        }

        const createSharedLinkResult = await createSharedLinkResponse.json();
        sharedLinkUrl = createSharedLinkResult.url;
      }

      // Modify the shared link to be a direct download link and generate a thumbnail URL
      const thumbnailUrl = sharedLinkUrl.replace('www.dropbox.com', 'dl.dropboxusercontent.com').replace('dl=0', 'raw=1');

      // Create HTML content with Open Graph meta tags
      const htmlContent = createHtmlTemplate(thumbnailUrl, title, description, sharedLinkUrl);
      const htmlFilename = filename.replace(/\.[^/.]+$/, "") + '.html';

      // Upload the HTML content to Dropbox
      const htmlBlob = new Blob([htmlContent], { type: 'text/html' });

      const htmlHeaders = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/octet-stream',
        'Dropbox-API-Arg': JSON.stringify({
          path: `/html/${htmlFilename}`,
          mode: 'add',
          autorename: true,
          mute: false,
        }),
      };

      const htmlUploadResponse = await fetch('https://content.dropboxapi.com/2/files/upload', {
        method: 'POST',
        headers: htmlHeaders,
        body: htmlBlob
      });

      if (!htmlUploadResponse.ok) {
        throw new Error(`Failed to upload HTML to Dropbox. Status: ${htmlUploadResponse.status} Response: ${htmlUploadResponse.statusText}`);
      }

      const htmlUploadResult = await htmlUploadResponse.json();

      // Create a shared link for the uploaded HTML file
      const htmlSharedLinkResponse = await fetch('https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          path: htmlUploadResult.path_lower,
          settings: {
            requested_visibility: 'public'
          }
        })
      });

      if (!htmlSharedLinkResponse.ok) {
        throw new Error(`Failed to create HTML shared link: ${htmlSharedLinkResponse.statusText}`);
      }

      const htmlSharedLinkResult = await htmlSharedLinkResponse.json();
      const sharePageUrl = htmlSharedLinkResult.url.replace('www.dropbox.com', 'dl.dropboxusercontent.com').replace('dl=0', 'raw=1');

      // Generate share URLs for social media platforms using the share page URL
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(sharePageUrl)}`;
      const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(sharePageUrl)}`;
      const instagramShareUrl = 'https://www.instagram.com/';
      // Set the share URLs in the state variables
      setFacebookShareUrl(facebookShareUrl);
      setTwitterShareUrl(twitterShareUrl);
      setInstagramShareUrl(instagramShareUrl);
      if (windowWidth <= 767) {
        setModalOpen(false);
      } else {
        setModalOpen(true);
      }
    } catch (error) {
      console.error('Error uploading image to Dropbox:', error);
      throw error;
    } finally {
      // Set loading state to false after API calls are done
      if (loaderRef.current) {
        loaderRef.current.style.display = 'none';
      }
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window]);

  return (
    <>
      <Loader ref={loaderRef} />
      <section className="display-section">
        <div className="step">
          <h2 className="section-heading">setja inn límmiða</h2>
          {windowWidth <= 767 ? (
            <div className="image-container navigation-wrapper responsive-mode">
              <div>
                <div ref={sliderRef} className="keen-slider">
                  {Object.entries(finalImages).map(([type, src], index) => (
                    <div key={index} className={`keen-slider__slide number-slide${index + 1}`}>
                      {type === "storyImage" && (
                        <h5 className="upload-image-description story">
                          samfélagsmiðlar – <strong>stories</strong><br /> <span>1080×1920</span>
                        </h5>
                      )}
                      {type === "postImage" && (
                        <h5 className="upload-image-description post">
                          samfélagsmiðlar – <strong>post</strong><br /> <span>1080×1080</span>
                        </h5>
                      )}
                      <div
                        key={index}
                        className={`image-section-${index + 1} image-preview ${index === 0 ? '' : ''}`}
                        style={{ position: "relative" }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}

                      >

                        <img className="trending-image" src={src} alt={`Final ${type}`} />
                        {showControls && (
                          <Moveable
                            target={overlayRefs.current[type]}
                            draggable={true}
                            resizable={true}
                            rotatable={true}
                            keepRatio={true}
                            onDragStart={(e) => helperRef.current.onDragStart(e)}
                            onDrag={(e) => helperRef.current.onDrag(e)}
                            onScaleStart={helperRef.current.onScaleStart}
                            onScale={(e) => helperRef.current.onScale(e)}
                            onRotateStart={helperRef.current.onRotateStart}
                            onRotate={(e) => helperRef.current.onRotate(e)}
                            onResizeStart={helperRef.current.onResizeStart}
                            onResize={(e) => {
                              helperRef.current.onResize(e);
                              handleResize(e);
                            }}
                          />
                        )}
                        <div
                          ref={(el) => (overlayRefs.current[type] = el)}
                          className={`${type}-overlay ticket-overlay`}
                        >
                          <div className="ticket-name">{ticketData.ticketName}</div>
                          <img className="ticket-image" src={ticketData.imageUrl} alt="Ticket" style={{ width: "100%", height: "auto", objectFit: "contain" }} />
                        </div>
                      </div>
                      <div className="display-section-btns responsive-capture-btns">
                        <div>
                          <div>
                            <div>
                              <button className="btn" onClick={() => handleCapture(index, type)}>Hlaða niður</button>
                            </div>
                            <div>
                              {/* <button className="btn" onClick={() => handleShare(index, type)}>Deila</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {loaded && instanceRef.current && (
                  <>
                    <Arrow
                      left
                      onClick={(e) => {
                        e.stopPropagation();
                        instanceRef.current?.prev();
                      }}
                      disabled={currentSlide === 0}
                    />

                    <Arrow
                      onClick={(e) => {
                        e.stopPropagation();
                        instanceRef.current?.next();
                      }}
                      disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
                    />
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="image-container desktop-mode">
              {Object.entries(finalImages).map(([type, src], index) => (
                <div key={index}>
                  {type === "storyImage" && (
                    <h5 className="upload-image-description story">
                      samfélagsmiðlar – <strong>stories</strong><br /> <span>1080×1920</span>
                    </h5>
                  )}
                  {type === "postImage" && (
                    <h5 className="upload-image-description post">
                      samfélagsmiðlar – <strong>post</strong> <br /> <span>1080×1080</span>
                    </h5>
                  )}
                  <div
                    key={index}
                    className={`image-section-${index + 1}  image-preview ${index === 0 ? '' : ''}`}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img className="trending-image" src={src} alt={`Final ${type}`} />
                    {showControls && (
                      <Moveable
                        target={overlayRefs.current[type]}
                        draggable={true}
                        resizable={true}
                        rotatable={true}
                        keepRatio={true}
                        onDragStart={(e) => helperRef.current.onDragStart(e)}
                        onDrag={(e) => helperRef.current.onDrag(e)}
                        onScaleStart={helperRef.current.onScaleStart}
                        onScale={(e) => helperRef.current.onScale(e)}
                        onRotateStart={helperRef.current.onRotateStart}
                        onRotate={(e) => helperRef.current.onRotate(e)}
                        onResizeStart={helperRef.current.onResizeStart}
                        onResize={(e) => {
                          helperRef.current.onResize(e);
                          handleResize(e);
                        }}
                      />
                    )}
                    <div
                      ref={(el) => (overlayRefs.current[type] = el)}
                      className={`${type}-overlay ticket-overlay`}
                    >
                      <div className="ticket-name">{ticketData.ticketName}</div>
                      <img className="ticket-image" src={ticketData.imageUrl} alt="Ticket" style={{ width: "100%", height: "auto" }} />
                    </div>
                  </div>
                  <div className="display-section-btns">
                    <div>
                      <div>
                        <div>
                          <button className="btn" onClick={() => handleCapture(index, type)}>Hlaða niður</button>
                        </div>
                        <div>
                          {/* <button className="btn" onClick={() => handleShare(index, type)}>Deila</button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <Modal id="social_modal" className="social-platform-modal text-center" show={modalOpen} onHide={closeModal} centered>
            <Modal.Header>
              <div className="cross-icon" onClick={closeModal}></div>
            </Modal.Header>
            <Modal.Body>
              <div className="social-platforms">
                <p>Deila á samfélagsmiðlum</p>
                <ul className="list-unstyled d-flex align-items-center justify-content-center">
                  <li>
                    <a href={facebookShareUrl} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a href={twitterShareUrl} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faXTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href={instagramShareUrl} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                </ul>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <div id="savePhotoModal" className="iphone-modal">
          <h6 className="iphone-guide">Haltu fingrinum á myndinni til þess að vista hana á þitt tæki</h6>
          <span id="closeIcon" className="close">&times;</span>
          <img id="displayedImage" className="modal-content" src="" alt="Displayed Image" />
        </div>
      </section>
    </>
  );
};

export default Step4;