import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const reloadPage = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <header>
      {showModal && (
        <div className="container-fluid">
          <div className="modal-backdrop">
            <div ref={modalRef} className="modal exit-modal">
              <div className="cross-icon"onClick={toggleModal}></div>
              <div className="exit-modal-content">
              <h2>Ertu viss um að þú viljir hætta?</h2>
              <p>Allar óvistaðar upplýsingar munu glatast.</p>
              <div className="button-container d-flex align-items-center justify-content-center">
                <button onClick={reloadPage} className="confirm-button">Já</button>
                <button onClick={toggleModal} className="cancel-button">Nei</button>
              </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="Header_header__NnOSM Header_isRegular__qmthJ">
        <div className="Header_header__content__w2qML">
          <Link onClick={toggleModal}
            className="Header_header__logo__iOMtl Header_marathon__DoDiA"
          >Reykjavíkur Maraþon Íslandsbanka
          </Link>
        </div>
      </div>
      <div className="Header_header__skew__UqQYk"></div>
    </header>
  )
}

export default Navbar
