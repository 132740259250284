import React, { useState, useEffect } from 'react';
import Step1 from './TextInputWithImage';
import Step2 from './FormStep';
import Step3 from './ImageUploadStep';
import Step4 from './TicketDisplay';
import ThankYou from './ThankYou';
import TicketImage from '../assets/images/ticket.png';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const Slider = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [accessToken, setAccessToken] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [handleUploadBothImages, setHandleUploadBothImages] = useState(() => { });


  useEffect(() => {
    const fetchRefreshToken = async () => {
      try {
        const response = await axios.get('https://rmi-backend-neon.vercel.app/refresh-token');
        const { refreshToken } = response.data;
        // Use the refresh token to exchange for an access token
        const tokenResponse = await axios.post('https://rmi-backend-neon.vercel.app/exchange-code', { code: refreshToken });
        const { accessToken } = tokenResponse.data;
        setAccessToken(accessToken);
      } catch (error) {
        console.error('Error fetching refresh token:', error.message);
      }
    };
    fetchRefreshToken();
  }, []);

  useEffect(() => { }, [accessToken]);

  const [activeStep, setActiveStep] = useState(0);
  const [ticketData, setTicketData] = useState({
    ticketName: '',
    imageUrl: TicketImage
  });
  const [formData, setFormData] = useState({
    ticketName: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    advertiseAccess: false
  });
  const [images, setImages] = useState({
    originalImage: null,
    storyImage: null,
    postImage: null,
  });
  const [editingState, setEditingState] = useState({ editing: null, show: false });
  const [inputValue, setInputValue] = useState(ticketData.ticketName || "");

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const isNextDisabled = () => {
    switch (activeStep) {
      case 0:
        return inputValue.trim() === "";
      case 1:
        return !formData.fullName || !formData.email || !formData.phoneNumber;
      case 2:
        return !images.originalImage || !images.storyImage || !images.postImage;
      default:
        return false;
    }
  };

  const onUpdateFormData = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const onSubmit = async () => {
    try {
      await axios.post('https://rmi-backend-neon.vercel.app/submit-form', formData);
    } catch (error) {
      console.error('Error submitting form:', error.message);
    }
  };

  const onNext = async (data) => {
    if (activeStep === 0) {
      setTicketData({ ...ticketData, ticketName: inputValue });
      setFormData({ ...formData, ticketName: inputValue });
    } else if (activeStep === 1) {
      setFormData({ ...formData, ...data });
      setIsSubmitting(true); 
      await onSubmit();
      setIsSubmitting(false);
    } else if (activeStep === 2) {
      setImages({ ...images, ...data });
    }
  
    if (activeStep === steps.length - 2) {
      setIsSubmitting(true);
      await handleUploadBothImages();
      setIsSubmitting(false); 
    }
  
    setActiveStep(prevStep => prevStep + 1);
  };
  
  const onPrev = () => {
    setActiveStep(prevStep => prevStep - 1);
    const previousLocation = location.state ? location.state.from : "/";
    navigate(previousLocation);
  };

  const steps = [
    { Component: Step1, props: { ticketData, onNext, onPrev, handleChange, inputValue, setInputValue, isNextDisabled } },
    { Component: Step2, props: { formData, onNext, onPrev, onUpdateFormData, isNextDisabled } },
    { Component: Step3, props: { images, setImages, editingState, setEditingState, onNext, onPrev, isNextDisabled } },
    { Component: Step4, props: { ticketData, images, onPrev, accessToken, email: formData.email, handleUploadBothImages: setHandleUploadBothImages } },
    { Component: ThankYou, props: { onPrev } }
  ];

  return (
    <div className="slider-container">
      <div className="slider">
        <div className={`step-content ${steps[activeStep].Component === ThankYou ? "thank-you" : ""}`}>
          <h2>{steps[activeStep].label}</h2>
          {renderStep()}
        </div>
      </div>
      <div className={`steps d-flex align-items-center justify-content-center ${activeStep === steps.length - 1 ? "steps-1" : ""} ${steps[activeStep].Component === ThankYou ? "d-none" : ""}`}>
        <button className={`slider-btn ${activeStep === 0 ? 'disabled' : ''}`} disabled={activeStep === 0} onClick={onPrev}>
          <FontAwesomeIcon icon={faChevronLeft} /> til baka
        </button>
        {steps.map((step, index) => (
          <div key={index} className={`dot ${activeStep === index ? 'active' : ''}`}></div>
        ))}
        <button className={`slider-btn ${isNextDisabled() ? 'disabled' : ''}`}   disabled={isNextDisabled() || isSubmitting}  onClick={onNext}>
          {activeStep === steps.length - 2 ? "Ljúka ferli" : "áfram "} <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );

  function renderStep() {
    const { Component, props } = steps[activeStep];
    return <Component {...props} />;
  }
};

export default Slider;
