import React from 'react';
import thanksBanner from '../assets/images/thanks-banner.png';
import Navbar from './Navbar';

const ThankYou = ({ onPrev }) => {
    return (
        <>
            <Navbar />
            <div className='thank-you-section'>
                <div className="Hero_hero__K4Cz8 Hero_hasImage__rBmEi">
                    <div className="Hero_hero__overlay__hbU7K">
                        <div className='thanks-heading'>
                            <div className="Hero_hero__headline__1KmOa mb-4">
                                <div className="Headline_headline__UC8XB">
                                    <h1 className="Headline_headline__wrapper__bpYIi">
                                        Takk fyrir þátttökuna
                                    </h1>
                                </div>
                            </div>
                            <div className='thank-you-information '>
                                <h5>NÚ GETUR ÞÚ DEILT ÞINNI <br /> MYND Á SAMFÉLAGSMIÐLUM <br /> OG UM LEIÐ SAGT ÞÍNA SÖGU</h5>
                            </div>
                        </div>
                        <div className="Hero_hero__outer__Zm_E6">
                            <div className="Hero_hero__stripe__uZSWZ"></div>
                        </div>
                    </div>
                    <div className='thank-you-content'>
                        <div className="Hero_hero__background__BnG4B">
                            <div className="Hero_hero__aspect__jz2xZ Hero_desktop__mn3_x" style={{ paddingTop: '56.25%' }}></div>
                            <div className="Hero_hero__aspect__jz2xZ Hero_mobile__S6hX_" style={{ paddingTop: '75%' }}></div>
                            <picture className="Hero_hero__image__GIBSR">
                                <source srcSet={thanksBanner} />
                                <img className="Hero_hero__image__GIBSR" src={thanksBanner} alt="image" width="800" height="600" />
                            </picture>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                    <a>
                    <button className="start-btn three btn" onClick={onPrev}>
                        <span className="icon me-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path d="M1 12H22" stroke="#ffffff" strokeWidth="1.5" />
                                <path
                                    d="M8 6L2 12L8 18"
                                    stroke="#ffffff"
                                    strokeWidth="1.5"
                                />
                            </svg>
                        </span>
                        Skoða myndina þína
                    </button>
                    </a>
                    <a href='https://www.rmi.is/'>
                        <button className="start-btn two btn">
                            Aftur á forsíðu rmi.is
                            <span className="icon ms-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path d="M1 12H22" stroke="#ffffff" strokeWidth="1.5" />
                                    <path
                                        d="M16 6L22 12L16 18"
                                        stroke="#ffffff"
                                        strokeWidth="1.5"
                                    />
                                </svg>
                            </span>
                        </button>
                    </a>
                    <a href='https://www.rmi.is/hlaupastyrkur'>
                        <button className="start-btn btn">
                            Opna hlaupastyrkur.is
                            <span className="icon ms-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path d="M1 12H22" stroke="#ffffff" strokeWidth="1.5" />
                                    <path
                                        d="M16 6L22 12L16 18"
                                        stroke="#ffffff"
                                        strokeWidth="1.5"
                                    />
                                </svg>
                            </span>
                        </button>
                    </a>
                </div>
            </div>
        </>
    );
};

export default ThankYou;
